<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="font-weight-bold">團購編號：{{ detailInfo.id }}</div>
      <div>商品名稱：{{ detailInfo.title }}</div>
      <div>群組名稱：{{ detailInfo.name }}</div>
      <div>狀態：{{ detailInfo.status }}</div>
      <div>開團時間： {{ detailInfo.start_at }}</div>
      <div>結單時間： {{ detailInfo.end_at }}</div>
    </div>

    <div class="col-12 col-xl-6">
      <template v-if="isGroupBuyEnd">
        <div>出貨時間： 2021-01-01 14:30</div>
        <div>到貨時間： 2021-01-01</div>
        <div>出貨組數：50</div>
      </template>
      <template v-else>
        <div>已下單組數：55</div>
        <div>未結團組數：5</div>
      </template>
      <div>累積總金額：2000</div>
      <div>實際總金額：1780</div>
    </div>
  </div>
</template>

<script>
import { GROUPBUYING_STATUS } from "./utils";

export default {
  props: ["status", "detailInfo"],
  data() {
    return {
      GROUPBUYING_STATUS,
    };
  },
  computed: {
    isGroupBuyEnd() {
      return this.status === GROUPBUYING_STATUS.FINISHED;
    },
  },
};
</script>
